/* global angular */
/** @ngInject */

function AIService ($rootScope, $q, $window, $mdToast, $state, AIPrompt) {
  const defaults = {
    vision: {
      prompt: `Analyze images concisely:<changeable>
    1. Limit responses to 50 words.
    1. Indicate unclear or missing information.</changeable>
    1. If AVAILABLE_OPTIONS provided, your response should be exactly like one of the options without additional text otherwise respond N/A.
    <AVAILABLE_OPTIONS>`,
      maxTokens: 4096,
      temperature: 1
    },
    askAI: {
      prompt: `You are a helpful assistant.`,
      maxTokens: 4096 * 2,
      temperature: 1
    },
    translate: {
      prompt: `Translate the following text from DEFAULT_CONTENT_LANGAGUGE to TARGET_CONTENT_LANGAGUGE.
          <changeable>Instructions:
          1. Preserve any merged tags (e.g., {{tag}} or {{unit.serial}}) without translating them.
          2. Strictly avoid translating text within single or double quotes (e.g., "Off", 'off', "Press Continue").
          3. Maintain the original formatting and structure of the text.
          4. Provide the translation as a JSON object with the original text as keys and the translations as values.
  </changeable>
          reply with new JSON. The response should be wrapped within markdown syntax \`\`\`json\`\`\`:\n\n`,
      temperature: 1,
      maxTokens: 4096
    }
  }

  const getDefaultSettings = type => defaults[type]

  class Completion {
    constructor (messages, callbacks = {}, options = {}) {
      this.messages = messages
      this.callbacks = callbacks
      this.options = options
      this.toolCalls = new Map()
      this.AbortController = null
      this.message = ''
      if (!this.callbacks.contentCallback) {
        this.callbacks.contentCallback = () => {}
      }
      if (!this.callbacks.callback) {
        this.callbacks.callback = () => {}
      }
    }

    send (content = '') {
      if (content) {
        this.messages.push({ role: 'user', content })
      }

      this.AbortController = new AbortController()
      const signal = this.AbortController.signal

      const payload = {
        messages: angular.copy(this.messages),
        options: this.options
      }
      // Create new message if last message is human
      this.messages.push({
        role: 'assistant',
        content: '',
        tool_calls: []
      })
      return $q((resolve, reject) => {
        fetch('/api/AI/completion', {
          method: 'POST',
          body: JSON.stringify(payload),
          signal,
          headers: {
            'Content-Type': 'application/json',
            Authorization: $rootScope.currentUser.accessTokenId
          }
        })
          .then(response => {
            if (!response.ok) {
              return response.json().then(err => {
                throw new Error(
                  err.error?.message || 'An unexpected error occurred'
                )
              })
            }
            return this.processStream(response)
          })
          .then(() => {
            resolve(this.messages[this.messages.length - 1].content)
          })
          .catch(error => {
            if (error.name === 'AbortError') {
              resolve(this.messages[this.messages.length - 1].content)
            } else {
              console.error('Fetch error:', error)
              $mdToast.show(
                $mdToast.nextplus({
                  position: $rootScope.toastLocation,
                  parent: 'body',
                  theme: 'error-toast',
                  hideDelay: 3000
                })
              )
              $mdToast.updateTextContent(error.message)
              reject(error)
            }
          })
      })
    }

    handleChunk = data => {
      const messages = this.messages
      if (data.type === 'content') {
        const currentMessage = messages.findLast(m => m.role === 'assistant')
        this.callbacks.contentCallback(data.delta.text)
        currentMessage.content += data.delta.text
      } else if (data.type === 'tool_arguments') {
        // Handle tool arguments
        const toolCall = this.toolCalls.get(data.tool_call_id)
        if (toolCall) {
          toolCall.arguments += data.delta.text
        }
      } else if (data.type === 'tool_result') {
        const assistantMessage = messages.findLast(m => m.role === 'assistant')
        const toolMessage = assistantMessage.tool_calls.find(
          t => t.id === data.tool_call_id
        )
        if (toolMessage) {
          toolMessage.result = data.artifact
        }
        messages.push({
          role: 'tool',
          content: data.artifact,
          tool_call_id: data.tool_call_id
        })
        // const msg = this.toolCalls.get(data.tool_call_id)
        // if (msg) {
        //   // Create a new tool message with the result
        //   const toolResultMessage = {
        //     role: 'tool',
        //     content: data.artifact,
        //     tool_call_id: data.tool_call_id,
        //     tool_use_id: data.tool_use_id || data.tool_call_id
        //   }

        //   // Replace the existing tool message with the result
        //   const toolMessageIndex = messages.findIndex(
        //     m => m.role === 'tool' && m.tool_call_id === data.tool_call_id
        //   )

        //   if (toolMessageIndex !== -1) {
        //     messages[toolMessageIndex] = toolResultMessage
        //   } else {
        //     messages.push(toolResultMessage)
        //   }

        //   // Mark the tool call as done
        //   msg.done = true
        // }
      } else if (data.type === 'tool_call') {
        if (data.tool_name) {
          // Find last message with 'assistant' role
          let lastAssistantMessage = messages.findLast(
            m => m.role === 'assistant'
          )
          if (!lastAssistantMessage) {
            const assistantMessage = {
              role: 'assistant',
              content: '',
              tool_calls: []
            }
            lastAssistantMessage = assistantMessage
            messages.push(assistantMessage)
          }

          // Add the tool call to the assistant message
          const toolCall = {
            id: data.tool_call_id,
            tool_use_id: data.tool_call_id,
            name: data.tool_name,
            argsString: '',
            result: null,
            args: {}
          }
          if (!lastAssistantMessage.tool_calls) {
            lastAssistantMessage.tool_calls = []
          }
          lastAssistantMessage.tool_calls.push(toolCall)
        } else if (data.arguments) {
          // Update the arguments for the tool call

          const lastAssistantMessage = messages.findLast(
            m => m.role === 'assistant'
          )

          if (lastAssistantMessage) {
            const lastToolCall =
              lastAssistantMessage.tool_calls[
                lastAssistantMessage.tool_calls.length - 1
              ]
            if (lastToolCall) {
              try {
                const args = JSON.parse(data.arguments)
                if (typeof args === 'object') {
                  if (Object.keys(args).length === 0) {
                    lastAssistantMessage.tool_calls.pop()
                  }
                } else {
                  throw new Error('Invalid arguments')
                }
              } catch (e) {
                if (lastToolCall.argsString) {
                  lastToolCall.argsString += data.arguments
                } else {
                  lastToolCall.argsString = data.arguments
                }
              }
            }
          }
        }
      } else if (data.type === 'error') {
        console.error(data.error)
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'body',
            theme: 'error-toast',
            hideDelay: 1000 * 30
          })
        )
        $mdToast.updateTextContent(JSON.stringify(data.error))
      }

      this.callbacks.callback(data)

      return data?.type === 'stop'
    }

    async processStream (response) {
      const reader = response.body.getReader()
      const decoder = new TextDecoder()
      let buffer = ''

      try {
        while (true) {
          const { done, value } = await reader.read()
          if (done) break

          const chunkText = decoder.decode(value)
          buffer += chunkText

          // Split on newlines \r\n
          const lines = buffer.split(/(\r\n)/)
          buffer = lines.pop() || '' // Keep the last partial line in the buffer

          for (const line of lines) {
            if (line.trim()) {
              try {
                this.processChunk(JSON.parse(line))
              } catch (e) {
                console.warn('Failed to parse chunk:', e)
              }
            }
          }
        }

        // Process any remaining data in buffer
        if (buffer.trim()) {
          try {
            this.processChunk(JSON.parse(buffer))
          } catch (e) {
            console.warn('Failed to parse final chunk:', e)
          }
        }
      } catch (error) {
        console.error('Error processing stream:', error)
        this.invokeCallback('', true) // Ensure we complete even on error
        throw error
      }
    }

    processChunk (payload) {
      const isComplete = this.handleChunk(payload)
      if (isComplete) {
        this.invokeCallback('', true)
      }
    }

    invokeCallback (content, isComplete = false) {
      this.callbacks.contentCallback(content, isComplete)
    }

    stop () {
      if (this.AbortController) {
        this.AbortController.abort()
      }
    }
  }

  const getSettings = (type, stateName) => {
    // For all prompt types, check database first
    const query =
      type === 'askAI' && stateName
        ? { type, state: stateName }
        : { type, default: true }

    return AIPrompt.find({
      filter: {
        where: query,
        limit: 1
      }
    }).$promise.then(prompts => {
      if (prompts && prompts.length) {
        const prompt = prompts[0]
        return {
          prompt: prompt.prompt,
          maxTokens: prompt.maxTokens,
          resourceIds: prompt.resourceIds,
          temperature: prompt.temperature
        }
      }

      // For askAI with state, fallback to default askAI prompt
      if (type === 'askAI' && stateName) {
        return AIPrompt.find({
          filter: {
            where: {
              type: 'askAI',
              default: true
            },
            limit: 1
          }
        }).$promise.then(defaultPrompts => {
          if (defaultPrompts && defaultPrompts.length) {
            const prompt = defaultPrompts[0]
            return {
              prompt: prompt.prompt,
              maxTokens: prompt.maxTokens,
              temperature: prompt.temperature
            }
          }
          throw new Error(`No default prompt found for type: ${type}`)
        })
      }

      throw new Error(`No default prompt found for type: ${type}`)
    })
  }

  const getPromptSettings = async (type, stateName) => {
    const settings = await getSettings(type, stateName)
    settings.plainPrompt = settings.prompt
      .replaceAll('<changeable>', '')
      .replaceAll('</changeable>', '')
    return settings
  }

  return {
    Completion,
    getPromptSettings,
    getSettings,
    getDefaultSettings
    // handleChunk
  }
}

module.exports = AIService
